import { Breakpoints } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';

type ImgProps = ImgHTMLAttributes<HTMLImageElement>;

interface ImageProps extends Omit<ImgProps, 'src'> {
  pcSrc: string | undefined;
  pcMa?: number | undefined; // 이미지 배율. 기존 2배로 설정되어 있는 상태로 250123 원본 배율 이미지 출력 옵션 추가
  mobileSrc: string | undefined;
  mobileMa?: number | undefined; // 이미지 배율. 기존 2배로 설정되어 있는 상태로 250123 원본 배율 이미지 출력 옵션 추가
}

const Image = styled<FC<ImageProps>>(
  ({ pcSrc, pcMa, mobileSrc, mobileMa, alt, ...props }) => {
    // URL 인코딩 (URL에 띄어쓰기가 들어가면 파싱에러 발생하여 인코딩 처리)
    const encodedPcSrc = useMemo(() => pcSrc && encodeURI(pcSrc), [pcSrc]);
    const encodedMobileSrc = useMemo(() => mobileSrc && encodeURI(mobileSrc), [
      mobileSrc,
    ]);

    return (
      <picture>
        <source
          srcSet={`${encodedMobileSrc} ${mobileMa || 2}x`}
          media={`(max-width: ${Breakpoints.mobile}px)`}
          type="image/png"
        />
        <source srcSet={`${encodedPcSrc} ${pcMa || 2}x`} />
        <img
          {...props}
          src={encodedPcSrc}
          alt={alt}
          css={`
            width: 100%;
          `}
        />
      </picture>
    );
  },
)``;

export default Image;
